/* src/styles.css */

.navText {
    color: #64ffda;
    font-size: 15px;
  }
  
  .text-button {
    background: none;
    border: none;
    color: #64ffda;
    cursor: pointer;
    font-size: 15px;
    outline: none;
    padding: 0;
    margin: 0;
  }
  